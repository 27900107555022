jQuery( document ).ready( function () {

    function bindSubmit( attributes ) {

        this.formContainer = attributes.formContainer;
        this.form = attributes.form;
        this.loader = attributes.loader;
        this.errorArea = attributes.errorArea;
        this.scrollTarget = attributes.scrollTarget;
        this.scrollTop = attributes.scrollTop;
        this.track = attributes.track;
        this.recaptcha = attributes.recaptcha;
    }

    bindSubmit.prototype = ( {
        constructor : bindSubmit,
        scopeSelf : function () {
            this.self = this;
        },
        //    offsetForOffCanvas();
        submit : function ( e ) {
            var self = this;

            // disable the submit button to disallow multiple submissions
            jQuery( 'input[type="submit"]' ).attr( 'disabled', 'disabled' );
            jQuery( 'small' ).each( function ( ) {
                
                jQuery( this ).html( '' );
                jQuery( this ).removeClass( 'error' );
            } );
            var formData = self.form.serialize( );
            var formDataArr = self.form.serializeArray( );
            var formAction = self.form.attr( 'action' );
            self.loader.css( "display", "inline-block" );
            jQuery( '.error' ).children( 'small' ).empty( );
            jQuery( '.error' ).removeClass( 'error' );
            self.errorArea.empty( );
            jQuery.ajax( {
                url : formAction,
                data : formData,
                type : 'POST',
                success : function ( content, msg ) {
                    self.errorArea.css( {
                        display : 'none'
                    } );
                    self.loader.css( {
                        display : 'none'
                    } );
                    var isValid = content.split( '|' )[0];

                    if ( isValid == 'Bad' ) { // Content validation failure
                        // re-enable the submit button so the user can submit the form again
                        jQuery( 'input[type="submit"]' ).removeAttr( 'disabled' );
                        var errors = content.split( '|' );
                        var errorsCount = jQuery( errors ).length;
                        errorsCount = errorsCount - 1;
                        self.errorArea.hide( ).html( '<h3 class="error">Ooops...do you mind checking a couple things?</h3>' );
                        self.errorArea.fadeIn( );
                        for ( var i = 1; i <= errorsCount; i++ ) {
                            var errorMessage = errors[i].split( '-' )[0].split( '^' )[0];
                            var errorID = errors[i].split( '^' )[1].replace( /<br>/g, '' );
                            jQuery( '#' + errorID ).addClass( 'error' );
                            jQuery( '#' + errorID ).prev( 'small' ).prev( 'label' ).addClass( 'error' );
                            jQuery( '#' + errorID ).prev( 'small' ).html( errorMessage ).addClass( 'error' );
                        }

                        // reset the recaptcha if it's being used
                        if ( self.recaptcha ) {
                            grecaptcha.reset();
                        }
                    } else if ( isValid == 'Bot' ) { // Recaptcha failure
                        jQuery( 'input[type="submit"]' ).removeAttr( 'disabled' );
                        var botMessage = content.split( '|' )[1];
                        self.errorArea.hide( ).html( '<h3 class="error">' + botMessage + '</h3>' );
                        self.errorArea.fadeIn( );

                    } else { // Success

                        var formHeight = self.formContainer.height( );
                        var successMessage = content.split( '|' )[1];
                        self.formContainer.empty( ).css( "height", formHeight ).hide( );
                        self.formContainer.html( '<h4>' + successMessage + '</h4>' ).fadeIn( 'fast' );
                        if ( self.track && typeof ( _gaq ) != 'undefined' ) {
                            _gaq.push( [ '_trackEvent', 'Contact Form Submission', 'Submit', 'Success' ] );
                        }
                    }

                    // scroll the window into place so everyone can see the lovely message
                    if ( isValid == 'Bad' ) {
                        //this.scrollTop = scroll_target.top - 70;
                    } else {
                        //this.scrollTop = scroll_target.top - 70;
                    }

                    jQuery( 'html, body' ).stop( ).animate( {
                        'scrollTop' : this.scrollTop
                    }, 500, function ( ) {
                        // do nothing on callback
                    } );
                }

            } ); // .ajax

        }
    } ); // END bindSubmit.prototype

    if ( $( '#form-area' ).length > 0 ) {
        var signUp = new bindSubmit( {
            formContainer : jQuery( "#form-area" ),
            form : jQuery( '#sing-up-form' ),
            loader : jQuery( '#form-loader' ),
            errorArea : jQuery( '#error_message_heading' ),
            scrollTarget : jQuery( '#form-area' ).offset(),
            scrollTop : jQuery( '#form-area' ).offset().top,
            track : false
        } );

        signUp.form.submit( function ( e ) {
            e.preventDefault();
            signUp.submit();
        } );
    }

    if ( $( '#booking-form' ).length > 0 ) {
        var booking = new bindSubmit( {
            formContainer : jQuery( "#booking-form-area" ),
            form : jQuery( '#booking-form' ),
            loader : jQuery( '#booking-loader' ),
            errorArea : jQuery( '#booking-error-message-heading' ),
            scrollTarget : jQuery( '#booking-form-area' ).offset(),
            scrollTop : jQuery( '#booking-form-area' ).offset().top,
            track : false,
            recaptcha : true
        } );

        booking.form.submit( function ( e ) {
            e.preventDefault();
            booking.submit();
        } );
    }

    if ( $( '#sign-form' ).length > 0 ) {
        var signUpModal = new bindSubmit( {
            formContainer : jQuery( "#sign-up-modal" ),
            form : jQuery( '#sign-form' ),
            loader : jQuery( '#sign-loader' ),
            errorArea : jQuery( '#sign_error_message' ),
            scrollTarget : jQuery( '#sign-up-modal' ).offset(),
            scrollTop : jQuery( '#sign-up-modal' ).offset().top,
            track : false,
            recaptcha : false
        } );

        signUpModal.form.submit( function ( e ) {
            e.preventDefault();
            signUpModal.submit();
        } );
    }


} ); // document ready

function submitBooking() {
    //console.log('submitBooking');
    $( '#booking-form' ).submit();
}
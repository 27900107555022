document.addEventListener( 'DOMContentLoaded', function () {

    $( '#mobile-menu-container' ).ocMenu( {
        openIcon : $( '#hamburger-icon' ),
        closeIcon : $( '#close-button' ),
        menuList : $( '#my-mobile-menu' ),
        accordian : false
    } );

    // prevent default behaviour for all elements with class .prevent-me
    $( '.prevent-me' ).click( function ( e ) {
        e.preventDefault();
    } );

    function highlightNav( locationArray, menuArray ) {
        for ( var i = 0; i < menuArray.length; i++ ) {
            if ( menuArray[i].hasChildNodes() ) {
                var theHREF = menuArray[i].querySelector( 'li a' ).getAttribute( 'href' ).replace( /\//g, '' );
                if ( theHREF.length > -0 && locationArray.indexOf( theHREF ) >= 0 ) {
                    menuArray[i].querySelector( 'li a' ).setAttribute( 'class', 'active' );
                }
            }
        }
    }

    //highlightNav( location.href.split( '/' ), document.getElementById( 'main-menu' ).childNodes );


    if ( $( '#faq-content' ).length > 0 ) {
        $( '#faq-content h3' ).click( function ( e ) {
            var $self = $( this );
            $( this ).next( 'p' ).fadeToggle( 'fast', 'swing', function () {
                console.log( $self.parent( 'li' ) );
                $self.parent( 'li' ).css( {
                    backgroundColor : ( ( $self.parent( 'li' ).css( 'background-color' ) == 'none' ) ? 'gray' : 'none' )
                } );
            } );

        } );
    }

    if ( $( '#left-bar' ).length > 0 ) {
        var $bar = $( '#left-bar' );
        var barPos = $bar.offset();

        $( window ).bind( 'scroll', function () {
            if ( ( barPos.top - $( window ).scrollTop() ) <= 0 ) {
                $bar.css( {
                    position : 'fixed',
                    top : '10px',
                    left : barPos.left
                } );
            } else {
                $bar.css( {
                    position : 'static'
                } );
            }
        } );

    }

    if ( $( '.scroll-link' ).length > 0 ) {
        $( '.scroll-link' ).click( function ( ev ) {
            ev.preventDefault();
            var scrollDest = $( $( this ).attr( 'href' ) ).offset().top;
            $( 'body' ).animate( {
                scrollTop : scrollDest
            }, 1000, 'easeInOutQuad' );
        } );
    }

    /**
     * Initialize Play Buttons if there are any
     * @type NodeList
     */
    var playButtons = document.querySelectorAll( '.play-audio' );
    playButtons.forEach( function ( element ) {
        element.addEventListener( 'click', function () {
            var theAudio = document.getElementById( element.dataset.audio );
            if ( theAudio.paused ) {
                theAudio.play();
                this.classList.add( 'active-button' );
                this.innerHTML = 'Pause Audio';
                this.blur();
            } else {
                theAudio.pause();
                this.classList.remove( 'active-button' );
                this.innerHTML = 'Play Audio';
                this.blur();
            }
        } );
    } );


} );



$( window ).load( function () {

    // Make the heights of the elements the same
    function uniformHeights() {
        // anything with a class of 'same-height' will be made to be as tall as the tallest of those elements
        if ( $( '.same-height' ).length > 0 ) {
            var heights = [ ];
            $( '.same-height' ).each( function () {
                heights.push( $( this ).height() );
            } );

            heights = heights.sort( function ( a, b ) {
                return a - b;
            } );

            var newHeight = heights.pop();

            $( '.same-height' ).each( function () {
                $( this ).height( newHeight + 'px' );
            } );

        }
    }

    // we don't need uniform heights on small screens. use Foundation's media query object to detect
    var bp = Foundation.MediaQuery.current;

    if ( bp !== 'small' ) {
        uniformHeights();
    }
} );
/**
 *
 * MOBILE MENU
 *
 *
 *
 **/
( function ( $ ) {

    $.fn.ocMenu = function ( options ) {

        var settings = $.extend( {
            menuContainer : this,
            menuList : null,
            openIcon : null,
            closeIcon : null,
            menuStyles : { },
            accordian : false,
            indentAmount : 30,
            menuLevels : 0,
            brandingBar : $( '#branding-bar' )

        }, options );

        var __ = {
            showMenu : function () {

                cascadeMenu();

                settings.menuContainer
                        .css( {
                            left : '0px',
                            opacity : 0
                        } )
                        .animate( {
                            opacity : 1
                        }, 300, function () {


                            settings.closeIcon
                                    .animate( {
                                        opacity : 1

                                    }, 200 );
                        } );

                settings.closeIcon
                        .toggleClass( 'closed-state' )
                        .css( {
                            opacity : 0,
                            left : settings.openIcon.offset().left - 1
                        } );

            },

            closeMenu : function () {
                settings.menuContainer.animate( {
                    opacity : 0
                }, 200, function () {
                    $( this ).css( {
                        left : '100vw'
                    } );
                    settings.closeIcon.toggleClass( 'closed-state' );
                    settings.closeIcon.css( {
                        left : '100vw'
                    } );

                    $( '#mobile-utility-container' ).css( {
                        top : '100vh'
                    } );
                } );
            },

            toggleMenu : function () {
                if ( settings.menuContainer.css( 'opacity' ) < 1 ) {
                    __.showMenu();
                    settings.openIcon.addClass( 'opened' );
                } else {
                    __.closeMenu();
                    settings.openIcon.removeClass( 'opened' );
                }

                console.log( settings.menuContainer.css( 'opacity' ) );

            }
        };

        settings.menuContainer.css( settings.menuStyles );

        settings.openIcon.click( function ( e ) {
            __.toggleMenu();
        } );

        settings.closeIcon.click( function ( e ) {
            //__.closeMenu();
        } );

        function cascadeMenu() {

            settings.menuList.css( {
                opacity : 1
            } );

            var number_of_items = settings.menuList.children().length;
            var interval_multiplier = number_of_items;
            var interval = 200; //in ms
            settings.menuList.children().each( function ( idx ) {
                $( this ).css( {
                    opacity : 0,
                    top : '-100px'
                } );
            } );
            settings.menuList.children().each( function ( idx ) {
                $( this ).animate( {
                    top : 0 + 'px',
                    opacity : 0.8
                }, interval * ( interval_multiplier + 1 ), 'easeInOutQuart' );
                interval_multiplier--;
            } );
        }
    };
}( jQuery ) );
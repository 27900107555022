$(window).bind(' load resize orientationChange ', function () {
   var footer = $("#footer-container");
   var footer_contents = $('#footer');
   var pos = footer.position();
   var height = $(window).height();
   height = height - pos.top;
   height = height - footer.height() -1;

   function stickyFooter() {
     footer.css({
         'padding-top': height + 'px'
     });

     footer_contents.css({
         'position' : 'relative',
         'top' : parseInt(-height + 20) + 'px'
     });
   }

   if (height > 0) {
     stickyFooter();
   }
});

$( document ).ready( function () {
    var videos = $( 'iframe[src*="vimeo.com"], iframe[src*="youtube.com"]' );

    videos.each( function () {
        var el = $( this );
        el.wrap( '<div class="responsive-embed large"/>' );
    } );


} );
